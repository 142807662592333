
import { defineComponent, createVNode } from "vue";
import {
  Tabs,
  Form,
  Button,
  Input,
  message,
  Popover,
  Cascader,
  Select,
  Modal,
  DatePicker,
  Empty,
  Table,
} from "ant-design-vue";
import {
  SyncOutlined,
  SearchOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
} from "@ant-design/icons-vue";
import PositionIcon from "@/components/masterMap/PositionIcon.vue";
import Regional from "@/components/currency/Regional.vue";
import Map from "@/components/masterMap/Map.vue";
import AuditLog from "@/components/masterMap/AuditLog.vue";
import { logList, repeatList, logDetail } from "@/api/terminalMap.js";
import { masterMapColumns } from "@/api/masterMap/fixedData.js";
import moment from "moment";
const { TabPane } = Tabs;
const { Item } = Form;
const { RangePicker } = DatePicker;

export default defineComponent({
  components: {
    Tabs,
    TabPane,
    Form,
    FormItem: Item,
    Button,
    Input,
    message,
    SyncOutlined,
    SearchOutlined,
    DownOutlined,
    PositionIcon,
    Popover,
    Regional,
    Cascader,
    Select,
    Map,
    Modal,
    ExclamationCircleOutlined,
    AuditLog,
    RangePicker,
    DatePicker,
    Empty,
    RightOutlined,
    Table,
  },
  props: {
    activeKey: {
      type: String,
      defaul: "2",
    },
  },
  data() {
    return {
      maxHeight: "100px", //整个外围盒子的高度
      resultHeight: "300px", //查询结果盒子的高度
      formState: {
        rangePicker: [], //日期
        auditStatus: "不通过", //审核状态
        // marker: "", //标记人
      },
      auditStatusOptions: [
        { value: "通过", label: "通过" },
        { value: "不通过", label: "不通过" },
        { value: "待审核", label: "待审核" },
      ],
      dataList: [],
      total: 0, //总数据量
      isEdit: 0, //标记是否更改数据 用于从新渲染地图
      showData: false,
      dates: [],
      detailsList: [],
      columns: masterMapColumns, //table的列名字
    };
  },
  methods: {
    reset() {
      this.formState = {
        rangePicker: [], //日期
        auditStatus: "不通过", //审核状态
        // marker: "", //标记人
      };
      this.showData = false;
      this.dataList = [];
      this.isEdit++;
    },
    search() {
      logList({
        auditStatus: this.formState.auditStatus,
        auditTimeStart:
          new Date(moment((this as any).formState.rangePicker[0]).format("YYYY-MM-DD") +
          " 00:00:00").getTime(),
        auditTimeEnd:
         new Date( moment((this as any).formState.rangePicker[1]).format("YYYY-MM-DD") +
          " 23:59:59").getTime(),
        // marker: this.formState.marker,
      }).then((res) => {
        this.dataList = res.data.data;
        this.total = res.data.total;
        this.isEdit++;
        this.showData = true;
      });
    },
    //设置颜色signType  null表示未标记 蓝色  1保留绿色 2重复红色
    checkColor(signType) {
      switch (signType) {
        case 1:
          return "#52C41A";
        case 2:
          return "#FF4D4F";
        default:
          return "#008BCF";
      }
    },
    checkSignType(signType) {
      switch (signType) {
        case 1:
          return "保留";
        case 2:
          return "重复";
        default:
          return "未标记";
      }
    },
    //更改日期配合设置禁用日期
    onCalendarChange(val) {
      (this as any).dates.value = val;
    },
    disabledDate(currentDate) {
      if (
        !(this as any).dates.value ||
        (this as any).dates.value.length === 0
      ) {
        return false;
      }
      let diffDate = currentDate.diff((this as any).dates.value[0], "months");
      return Math.abs(diffDate) > 0;
    },
    onOpenChange(open) {
      if (open) {
        (this as any).dates.value = [];
      }
    },
    //键盘事件
    keyDown() {
      let that = this;
      window.onkeydown = function (event) {
        //在全局中绑定按下事件
        var e = event;
        var keyCode = e.keyCode;
        switch (keyCode) {
          case 13:
            if (
              that.formState.rangePicker.length === 2 &&
              that.activeKey === "2"
            ) {
              that.search();
            }
            break;
        }
      };
    },
    //点击item触发子传父
    clickItem(item) {
      logDetail(item.auditCode).then((res: any) => {
        if (res.data.success) {
          res.data.data.map((el, index) => {
            el.beforeIndex = index;
          });
          this.detailsList = res.data.data;
          this.$emit("clickItem", res.data.data);
        }
      });
    },
    clickPop(item) {
      logDetail(item.auditCode).then((res: any) => {
        if (res.data.success) {
          res.data.data.map((el, index) => {
            el.beforeIndex = index;
          });
          this.detailsList = res.data.data;
          this.$emit("clickItem", res.data.data);
        }
      });
    },
    computedReason() {
      if (this.detailsList.length !== 0) {
        return (this as any).detailsList[0].reason;
      } else {
        return "";
      }
    },
  },
  emits: ["clickItem", "isEditItem"],
  watch: {
    isEdit() {
      this.$emit("isEditItem");
    },
  },
  mounted() {
    this.keyDown();
    this.maxHeight = document.body.clientHeight - 160 + "px";
    this.resultHeight = document.body.clientHeight - 480 + "px";
  },
});
