import request from '../utils/request.js';
// 终端省市区级联带搜索（二期）
export function provinceCityAre() {
  return request().get(`/api/base/v1/terminal/select/province/city/area`)
}
//平台重复终端列表（二期）
export function repeatList(params) {
  return request().post('/api/base/v1/terminal/repeat/list', {
    ...params,
  })
}
//终端下拉列表-重复终端关键字检索（二期）
export function selectList(params) {
  return request().post('/api/base/v1/terminal/select/list', {
    ...params,
  })
}
//平台重复终端标记终端列表（二期）
export function signList(params) {
  return request().post('/api/base/v1/terminal/repeat/sign/list', {
    ...params,
  })
}
//平台重复终端修改坐标（二期）
export function updateCoordinate(params) {
  return request().post('/api/base/v1/terminal/repeat/update/coordinate', {
    ...params,
  })
}
//终端地图日志检索
export function logList(params) {
  return request().post('/api/base/v1/terminal/repeat/log/list', {
    ...params,
  })
}
//重复终端日志详情
export function logDetail(code) {
  return request().get(`/api/base/v1/terminal/repeat/log/detail?auditCode=${code}`)
}
//终端检索关键词（二期）
export function listKeywords(terminalName, subChannel='') {
  return request().get(`/api/base/v1/terminal/repeat/list/keywords?terminalName=${terminalName}&subChannel=${subChannel}`)
}

