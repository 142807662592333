<template>
  <div>
    <div
      :style="{
        width: '22px',
        height: '22px',
        'border-radius': '50%',
        background: this.bgcolor,
        position: 'relative',
      }"
    >
      <span
        style="
          font-size: 16px;
          color: #fff;
          position: absolute;
          z-index: 99;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          top: -1px;
        "
        >
        {{ index }}
        </span
      >
    </div>
    <div style="width: 22px; position: relative">
      <div
        :style="{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-49%, -50%)',
          width: '0',
          height: '0',
          'border-left': '11px solid transparent',
          'border-right': '11px solid transparent',
          'border-top': '18px solid ' + this.bgcolor,
        }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    index: {
      type: Number,
      default: 1,
    },
    bgcolor: {
      type: String,
      default: "#f00",
    },
  },
};
</script>