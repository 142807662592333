
//品牌管理  column
export const masterMapColumns = [
    {
        title: "#",
        dataIndex: "fmesCode",
        slots: { customRender: "serialNumber" },
        width: 50,
    },
    {
        title: "终端名称/编码",
        dataIndex: "terminalName",
        slots: { customRender: "terminalName" },
        width: 250,
    },
    {
        title: "详细地址",
        dataIndex: "address",
        width: 250,
    },
    {
        title: "渠道",
        dataIndex: "channel",
        width: 100,
    },
    {
        title: "子渠道",
        dataIndex: "subChannel",
        width: 100,
    },
    {
        title: "标记类型",
        dataIndex: "signType",
        slots: { customRender: "signType" },
        width: 100,
    },
]