//终端地图验证规则
const checkProvince = async (rule, value) => {
    if (value.length <= 1) {
        return Promise.reject('请先选择省市（区）');
    }
    // if (value.length = 1) {
    //     return Promise.reject('省市都需要选择');
    // }
}


export const terminalMapRules = {
    fmesName: [
        {
            required: true,
            trigger: "blur",
            message: '请输入终端名称',
        },
        { min: 0, max: 200, message: "最多200字符", trigger: "blur" },
    ],
    keyWords: [
        {
            required: true,
            trigger: "blur",
            message: '请输入关键词',
        },
    ],
    province: [
        {
            required: true,
            validator: checkProvince,
            trigger: "change",
        },
    ]
}