<template>
  <div>
    <div class="toolBox">
      <div class="title">工具</div>
      <div @click="openDis" class="disTool">
        <img src="@/assets/pos/Ruler.svg" alt="" />
      </div>
    </div>
    <div
      id="mapContainer"
      class="baiduMap"
      :style="{ height: maxHeight }"
    ></div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
var myDis;
// import { message } from "ant-design-vue";
import DistanceTool from "./index.js";
export default defineComponent({
  components: {},
  data() {
    return {
      flag: 0,
      maxHeight: "200px",
    };
  },
  props: {
    dataList: {
      type: Array,
      defaul: [],
    },
    isEdit: {
      type: Number,
      defaul: 0,
    },
    allowRightClick: {
      type: Boolean,
      defaul: false,
    },
  },
  methods: {
    bdMap() {
      //创建地图
      let map = new BMap.Map("mapContainer", {
        enableMapClick: false, //禁用百度默认的地图标记点标注
      });
      //添加测距工具  此工具是gitHub上下载的index.js
      myDis = new DistanceTool(map);
      // 监听测距过程中的鼠标事件
      // myDis.addEventListener("drawend", function (e) {
      //   console.log("drawend");
      //   console.log(e.points);
      //   console.log(e.overlays);
      //   console.log(e.distance);
      // });
      // myDis.addEventListener("addpoint", function (e) {
      //   console.log("addpoint");
      //   console.log(e.point);
      //   console.log(e.pixel);
      //   console.log(e.index);
      //   console.log(e.distance);
      // });
      // myDis.addEventListener("removepolyline", function (e) {
      //   console.log("removepolyline");
      //   console.log(e);
      // });
      map.clearOverlays();
      map.addControl(new BMap.ScaleControl());
      map.addControl(
        new BMap.NavigationControl({
          anchor: BMAP_ANCHOR_TOP_RIGHT,
          type: BMAP_NAVIGATION_CONTROL_SMALL,
        })
      );
      map.enableScrollWheelZoom(true); //允许鼠标滚动缩放
      // this.baiduLon, this.baiduLat  116.404, 39.915
      if (this.dataList.length === 0) {
        map.centerAndZoom(
          new BMap.Point(116.404, 39.928),
          16 //缩放级别
        );
      } else {
        let bigBaiduLon = 0;
        let bigBaiduLat = 0;
        this.dataList.forEach((el) => {
          if (el.isCenter) {
            bigBaiduLon = el.baiduLon;
            bigBaiduLat = el.baiduLat;
          }
        });
        map.centerAndZoom(
          new BMap.Point(
            bigBaiduLon || this.dataList[0].baiduLon,
            bigBaiduLat || this.dataList[0].baiduLat
          ),
          15 //缩放级别
        );

        // 创建点标记
        // 在地图上添加点标记
        this.dataList.forEach((ele) => {
          let size = [33, 37]; //设定图片大小
          //   //加大图标
          if (ele.big) {
            size = [41, 47];
            switch (ele.signType) {
              case 1:
                var myIcon = new BMap.Icon(
                  require("@/assets/pos/green54.svg"),
                  new BMap.Size(...size),
                  { anchor: new BMap.Size(27, 46) }
                ); //创建图标保留终端的
                break;
              case 2:
                var myIcon = new BMap.Icon(
                  require("@/assets/pos/red54.svg"),
                  new BMap.Size(...size),
                  { anchor: new BMap.Size(27, 46) }
                ); //创建图标重复终端的
                break;
              default:
                var myIcon = new BMap.Icon(
                  require("@/assets/pos/blue54.svg"),
                  new BMap.Size(...size),
                  { anchor: new BMap.Size(27, 46) }
                ); //创建图标未标记的
                break;
            }
          } else {
            switch (ele.signType) {
              case 1:
                var myIcon = new BMap.Icon(
                  require("@/assets/pos/green42.png"),
                  new BMap.Size(...size),
                  { anchor: new BMap.Size(20, 36) }
                ); //创建图标保留终端的
                break;
              case 2:
                var myIcon = new BMap.Icon(
                  require("@/assets/pos/red42.png"),
                  new BMap.Size(...size),
                  { anchor: new BMap.Size(20, 36) }
                ); //创建图标重复终端的
                break;
              default:
                var myIcon = new BMap.Icon(
                  require("@/assets/pos/blue42.png"),
                  new BMap.Size(...size),
                  { anchor: new BMap.Size(20, 36) }
                ); //创建图标未标记的
                break;
            }
          }

          var marker = new BMap.Marker(
            new BMap.Point(ele.baiduLon, ele.baiduLat),
            {
              icon: myIcon,
              enableDragging: ele.coordinate ? true : false, //允许拖拽
            }
          );
          var labelText = ele.beforeIndex + 1;
          if (ele.animation) {
            labelText = "";
          }
          let offset = [17, 11];
          let fontSize = "12px";
          if (ele.big) {
            offset = [22, 16];
            fontSize = "16px";
            marker.setTop("true");
          }
          var label = new BMap.Label(labelText, {
            undefined,
            offset: new BMap.Size(...offset),
          });
          label.setStyle({
            undefined,
            fontSize: fontSize,
            background: "none",
            color: "#fff",
            border: "none", //只要对label样式进行设置就可达到在标注图标上显示数字的效果
          });
          marker.setLabel(label);
          map.addOverlay(marker);
          //添加左键点击标注信息
          var opts = {
            width: 120,
            height: 20,
            title: ele.fmesName,
          };
          var infoWindow = new BMap.InfoWindow("暂无详情", opts);
          // 点标记添加点击事件
          marker.addEventListener("click", function () {
            map.openInfoWindow(
              infoWindow,
              new BMap.Point(ele.baiduLon, ele.baiduLat)
            ); // 开启信息窗口
          });

          //给标记添加右键菜单
          if (
            this.$store.state.authc.authc
              .ButtonBasic_pg_basiData_terminalMap_sign
          ) {
            var markerMenu = new BMap.ContextMenu();
            markerMenu.addItem(
              new BMap.MenuItem("标为保留终端", (e, ee, marker) => {
                // console.log(marker.point);
                // {lng: 113.902594881511, lat: 29.72534723829857, Ye: 'inner'}
                this.rightClick(1, marker.point);
              })
            );
            markerMenu.addItem(
              new BMap.MenuItem("标为重复终端", (e, ee, marker) => {
                this.rightClick(2, marker.point);
              })
            );
            markerMenu.addItem(
              new BMap.MenuItem("取消标记", (e, ee, marker) => {
                this.rightClick(3, marker.point);
              })
            );
            if (
              this.$store.state.authc.authc
                .ButtonBasic_pg_basiData_terminalDetails_edit
            ) {
              markerMenu.addItem(
                new BMap.MenuItem("修改坐标", (e, ee, marker) => {
                  this.rightClick(4, marker.point);
                })
              );
            }
          }
          // markerMenu.addItem(
          //   new BMap.MenuItem("终端打卡详情", (e, ee, marker) => {
          //     this.rightClick(5, marker.point);
          //   })
          // );
          //仅allowRightClick为真才添加右键菜单
          // console.log(this.allowRightClick);
          if (this.allowRightClick && ele.auditStatus !== "待审核") {
            marker.addContextMenu(markerMenu);
          }
          //拖拽触发事件
          let that = this;
          function sendposition(lngvalue, latvalue) {
            lngvalue = lngvalue.toFixed(6);
            latvalue = latvalue.toFixed(6);
            that.$emit("dragendMarker", {
              baiduLon: lngvalue,
              baiduLat: latvalue,
            });
          }
          //标记点添加拖拽监听事件
          marker.addEventListener("dragend", function (e) {
            sendposition(e.point.lng, e.point.lat);
          });
          //点击修改的标记添加动画
          if (ele.animation) {
            marker.setAnimation(BMAP_ANIMATION_BOUNCE);
          }
        });
      }
    },
    //测距事件
    openDis() {
      myDis.open();
    },
    //右键菜单触发的事件 1标记为保留  2重复  3取消 4修改坐标 5显示打卡详情
    rightClick(type, point) {
      this.$emit("rightClick", type, point);
    },
  },

  mounted() {
    this.bdMap();
    this.maxHeight = document.body.clientHeight - 164 + "px";
  },
  watch: {
    isEdit() {
      this.bdMap();
    },
  },
});
</script>
<style lang="scss" scoped>
.baiduMap {
  border-radius: 0 5px 5px 0;
  width: 63%;
  height: 280px;
  position: absolute;
  right: 2px;
  top: 2px;
  margin: auto;
}
.toolBox {
  position: fixed;
  top: 151px;
  right: 87px;
  z-index: 9999;
  display: flex;
  border: 1px solid #ccc;
  .title {
    background: #008bcf;
    color: #fff;
    line-height: 40px;
    width: 40px;
    text-align: center;
  }
  .disTool {
    width: 50px;
    height: 40px;
    background: #ffffff;
    cursor: pointer;
    position: relative;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 20px;
    }
  }
}
</style>
<style lang="scss">
// .BMap_bubble_title {
// }
.BMap_bubble_content {
  color: #ccc;
}
</style>
