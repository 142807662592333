
//处理select值映射
function recursion(array) {
  array.forEach((element) => {
    element.value = element.name;
    element.label = element.name;
    if (element.children) {
      recursion(element.children);
    }
  });
}
import { defineComponent, createVNode } from "vue";
import {
  Tabs,
  Form,
  Button,
  Input,
  message,
  Popover,
  Cascader,
  Select,
  Modal,
  Empty,
  Table,
} from "ant-design-vue";
import {
  SyncOutlined,
  SearchOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import PositionIcon from "@/components/masterMap/PositionIcon.vue";
import Regional from "@/components/currency/Regional.vue";
import Map from "@/components/masterMap/Map.vue";
import AuditLog from "@/components/masterMap/AuditLog.vue";
import {
  provinceCityAre,
  repeatList,
  selectList,
  signList,
  updateCoordinate,
  listKeywords,
} from "@/api/terminalMap.js";
import { getTown } from "@/api/terminalList.js";
import { terminalMapRules } from "@/rules/terminalMap.js";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
const { TabPane } = Tabs;
const { Item } = Form;
export default defineComponent({
  components: {
    Table,
    DecorateModalTitle,
    Tabs,
    TabPane,
    Form,
    FormItem: Item,
    Button,
    Input,
    message,
    SyncOutlined,
    SearchOutlined,
    RightOutlined,
    PositionIcon,
    Popover,
    Regional,
    Cascader,
    Select,
    Map,
    Modal,
    ExclamationCircleOutlined,
    AuditLog,
    Empty,
  },
  data() {
    return {
      maxHeight: "100px", //整个外围盒子的高度
      resultHeight: "300px", //查询结果盒子的高度
      formState: {
        province: [], //省市区数组
        town: null, //街道
        fmesName: null,
        keyWords: null, //关键词 暂时没有接口
        scape: 1000, //距离 暂时也没有接口好像
      },
      // visiblePopover: false,
      cascaderOptions: [],
      townOptions: [],
      scapeOptions: [
        { value: 500, label: "0.5KM" },
        { value: 1000, label: "1KM" },
        { value: 1500, label: "1.5KM" },
        { value: 2000, label: "2KM" },
        { value: 2500, label: "2.5KM" },
        { value: 3000, label: "3KM" },
      ],
      fmesNameOptions: [],
      areaCode: "", //存储区code变量给街道使用
      rules: terminalMapRules,
      dataList: [],
      vallueForThrottle1: "",
      vallueForThrottle2: "",
      total: 0, //总数据量
      isEdit: 0, //标记是否更改数据 用于从新渲染地图
      showData: false,
      activeKey: "1", //当前激活页
      visibleModal: false,
      confirmLoading: false,
      columns: [
        {
          title: "终端名称/编码",
          dataIndex: "terminalName",
          slots: { customRender: "terminalName" },
          fixed: "left",
          width: 250,
        },
        {
          title: "详细地址",
          dataIndex: "address",
          slots: { customRender: "province" },
          width: 250,
        },
        {
          title: "渠道",
          dataIndex: "channel",
          width: 100,
        },
        {
          title: "子渠道",
          dataIndex: "subChannel",
          width: 100,
        },

        {
          title: "百度坐标",
          dataIndex: "baiduLat",
          slots: { customRender: "baiduLat" },
          width: 100,
        },
        {
          title: "操作",
          dataIndex: "signType",
          slots: { customRender: "signType" },
          width: 100,
        },
      ],
      underReview: false, //是否数据正在审核中
    };
  },
  methods: {
    //隐藏pop框
    // hidePop() {
    //   this.visiblePopover = false;
    // },
    //省市区获取焦点
    focusCascader() {
      if (this.cascaderOptions.length === 0) {
        provinceCityAre().then((res: any) => {
          recursion(res.data.data);
          this.cascaderOptions = res.data.data;
        });
      }
    },
    //街道获取焦点
    focusTown() {
      (this as any).$refs.formRef.validateFields(["province"]);
      getTown(this.areaCode).then((res: any) => {
        res.data.data.map((ele) => {
          ele.value = ele.name;
          ele.label = ele.name;
        });
        this.townOptions = res.data.data;
      });
    },
    changeCascader(value, selectedOptions) {
      (this as any).areaCode = selectedOptions[selectedOptions.length - 1].code; //存储区变量给街道使用
      (this as any).formState.town = null;
      (this as any).formState.fmesName = null;
      (this as any).fmesNameOptions = [];
      console.log(this.formState.province);
    },
    //根据省市区获取终端名称列表
    selectListName() {
      (this as any).$store.state.reqList.f();
      setTimeout(() => {
        (this as any).$store.commit("changeloadingStatus", true);
      }, 0);
      selectList({
        fmesName: this.vallueForThrottle2,
        province: this.formState.province[0],
        city: this.formState.province[1],
        area: this.formState.province[2],
        town: this.formState.town || "",
      }).then((res) => {
        let array = res.data.data.map((item) => {
          return { value: item.fmesCode, label: item.fmesName };
        });
        this.fmesNameOptions = array;
      });
    },
    //搜索终端名称功能
    searchFmesName(value: string) {
      if (this.formState.province.length === 0) {
        return;
      }
      this.vallueForThrottle1 = value;
      let that = this;
      setTimeout(function () {
        if (value !== "") {
          that.vallueForThrottle2 = value;
        }
      }, 200);
    },
    focusFmesName() {
      (this as any).$refs.formRef.validateFields(["province"]).then(() => {
        this.selectListName();
      });
    },
    reset() {
      (this as any).$refs.formRef.resetFields();
      this.formState = {
        province: [],
        town: null,
        fmesName: null,
        keyWords: null,
        scape: 1000,
      };
      this.showData = false;
      this.dataList = [];
      this.isEdit++;
    },
    search() {
      (this as any).$refs.formRef.validate().then(() => {
        this.underReview = false;
        repeatList({
          fmesCode: this.formState.fmesName,
          province: this.formState.province[0],
          city: this.formState.province[1],
          area: this.formState.province[2],
          town: this.formState.town || "",
          keyWords: this.formState.keyWords,
          scape: this.formState.scape,
          pageNumber: null,
          pageSize: null,
        }).then((res) => {
          res.data.data.forEach((ele, index) => {
            ele.beforeIndex = index;
          });
          this.dataList = res.data.data;
          this.total = res.data.total;
          this.isEdit++;
          this.showData = true;
          this.dataList.forEach((el: any) => {
            if (el.auditStatus === "待审核") {
              this.underReview = true;
              return;
            }
          });
        });
      });
    },
    //设置颜色signType  null表示未标记 蓝色  1保留绿色 2重复红色
    checkColor(signType) {
      switch (signType) {
        case 1:
          return "#52C41A";
        case 2:
          return "#FF4D4F";
        default:
          return "#008BCF";
      }
    },
    checkSignType(signType) {
      switch (signType) {
        case 1:
          return "保留";
        case 2:
          return "重复";
        default:
          return "标记终端";
      }
    },
    //删除标记
    delSignType(item) {
      let that = this;
      that.isEdit++;
      that.dataList.forEach((ele: any) => {
        if (ele.fmesCode === item.fmesCode) {
          // that.dataList.splice(index, 1);
          ele.signType = null;
        }
      });
    },
    //点击修改
    coordinate(item) {
      this.dataList.forEach((el: any) => {
        el.coordinate = false; //是否可修改的状态
        el.animation = false; //是否跳动的状态
        el.isCenter = false; //是否是中心
        // el.big = false;
      });
      item.coordinate = true;
      // item.big = true;
      item.animation = true;
      item.isCenter = true;
      this.isEdit++;
    },
    clickForBig(item) {
      this.dataList.forEach((el: any) => {
        el.big = false; //是否变大状态
        el.isCenter = false; //是否是中心
      });
      item.big = true;
      item.isCenter = true;
      this.isEdit++;
    },
    //地图标记点拖拽之后触发
    dragendMarker(pos) {
      this.dataList.forEach((el: any) => {
        if (el.coordinate) {
          updateCoordinate({
            fmesCode: el.fmesCode,
            baiduLat: el.baiduLat,
            baiduLon: el.baiduLon,
          }).then(() => {
            el.baiduLon = pos.baiduLon;
            el.baiduLat = pos.baiduLat;
            el.animation = false; //停止动画
            //通知地图重新渲染
            this.isEdit++;
          });
          return;
        }
      });
    },
    //地图右键触发事件
    rightClick(type, point) {
      let baiduLon = point.lng;
      let baiduLat = point.lat;
      this.dataList.forEach((el: any) => {
        if (el.baiduLon == baiduLon && el.baiduLat == baiduLat) {
          switch (type) {
            case 1:
              el.signType = 1;
              this.isEdit++;
              break;
            case 2:
              el.signType = 2;
              this.isEdit++;
              break;
            case 3:
              this.delSignType(el);
              break;
            case 4:
              this.coordinate(el);
              break;
            case 5:
              //打卡详情暂无接口
              this.isEdit++;
              break;
            default:
              break;
          }
        }
      });
    },
    //键盘事件
    keyDown() {
      let that = this;
      window.onkeydown = function (event) {
        //在全局中绑定按下事件
        var e = event;
        var keyCode = e.keyCode;
        switch (keyCode) {
          case 13:
            if (that.activeKey === "1") {
              that.search();
            }
            break;
          case 27:
            that.dataList.forEach((el: any) => {
              el.coordinate = false; //是否可修改的状态
              el.animation = false; //是否跳动的状态
            });
            that.isEdit++;
            break;
        }
      };
    },
    //完成标记禁用逻辑
    disabledOk() {
      if (
        !(this as any).$store.state.authc.authc
          .ButtonBasic_pg_basiData_terminalMap_sign
      ) {
        return true; //如果没有权限直接被禁用
      }
      let signType1 = 0;
      let signType2 = 0;
      // let length = this.dataList.length;
      this.dataList.forEach((el: any) => {
        if (el.signType === 1) {
          signType1++;
        }
        if (el.signType === 2) {
          signType2++;
        }
      });
      // if (signType1 === 1 && length === signType2 + 1 && signType2 > 0) {
      if (signType1 === 1 && signType2 >= 1 && !this.underReview) {
        return false;
      } else {
        return true;
      }
    },
    //点击完成标记
    handleOk() {
      this.visibleModal = true;
      this.confirmLoading = false;
    },
    handleModalOk() {
      this.confirmLoading = true;
      let terminalRepeatSignTypeLst = this.dataList
        .map((el: any) => {
          return { fmesCode: el.fmesCode, signType: el.signType };
        })
        .filter((el) => el.signType);
      // let PList: any = [];
      // // 先全部修改坐标
      // this.dataList.forEach((el: any) => {
      //   let P = updateCoordinate({
      //     fmesCode: el.fmesCode,
      //     baiduLat: el.baiduLat,
      //     baiduLon: el.baiduLon,
      //   });
      //   // PList.push(P);
      // });
      //坐标修改完了再标记状态
      // Promise.all(PList)
      //   .then(() => {
      signList({
        terminalRepeatSignTypeLst,
      })
        .then((res) => {
          if (res.data.success) {
            this.confirmLoading = false;
            this.visibleModal = false;
            message.success("标记提交成功");
            this.reset();
          } else {
            this.confirmLoading = false;
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        });
      // })
      // .catch(() => {
      //   this.confirmLoading = false;
      // });
    },
    clickItem(list) {
      this.dataList = list;
      this.isEdit++;
    },
    isEditItem() {
      this.isEdit++;
    },
    getKeywords(value, option) {
      listKeywords(option.label).then((res: any) => {
        this.formState.keyWords = res.data.data;
      });
    },
    filterOption(inputValue, option) {
      // console.log(inputValue, option);
      return true;
    },
  },
  watch: {
    vallueForThrottle2() {
      if (this.vallueForThrottle1 === this.vallueForThrottle2) {
        this.selectListName();
      }
    },
    activeKey() {
      (this as any).$refs.formRef.resetFields();
      this.dataList = [];
      this.showData = false;
      this.isEdit++;
    },
    underReview() {
      if (this.underReview) {
        Modal.warning({
          title: "提示",
          icon: () => createVNode(ExclamationCircleOutlined),
          content:
            "检索结果中存在待审核记录，不允许继续标记列表中的终端，请修改检索条件重新检索",
        });
      }
    },
  },
  mounted() {
    (this as any).dataList = [];
    this.maxHeight = document.body.clientHeight - 160 + "px";
    this.resultHeight = document.body.clientHeight - 629 + "px";
    this.keyDown();
    provinceCityAre().then((res: any) => {
      recursion(res.data.data);
      this.cascaderOptions = res.data.data;
    });
  },
});
